import React, { Fragment } from "react";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import {
    ColumnValueType,
    NumberFormat,
    TableColumn,
    TableList,
    VerticalBarChart,
} from "../../Controls";
import {
    DocumentCard,
    DocumentCardDetails,
    DocumentCardTitle,
    DocumentCardType,
    Dropdown,
    IDropdownOption,
    Label,
    Stack,
} from "@fluentui/react";
import { IDataItem, Typename } from "../../DataContract";
import { ExpandCard } from "../../Controls/Common/ExpandCard";
import { FullScreen } from "../Common/FullScreen";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { exportGeneralMetrics } from "../../Utils/ExportFile";
import { getColorByIndex } from "../../Utils";
import { TransformedDataItem } from "./OcrDataContract";
import { LargeModelSimilarityItem } from "./LargeModelByImage";

// prettier-ignore
const OVERVIEW_COLUMNS: TableColumn[] = [
    { key: "category",          name: "Category",            fieldName: "category",          isKey: true,   valueType: ColumnValueType.String,  minWidth: 100,  maxWidth: 200,  isResizable: true, distinctStr: true },
    { key: "f1_score",          name: "f1_score (%)",        fieldName: "f1_score",          isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 200,  isResizable: true, numberFormat: NumberFormat.Percentage,  maxDecimalPlaces: 2},
    { key: "f2_score",          name: "f2_score (%)",        fieldName: "f2_score",          isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 200,  isResizable: true, numberFormat: NumberFormat.Percentage,  maxDecimalPlaces: 2},
    { key: "matched",           name: "matched",             fieldName: "matched",           isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 250,  isResizable: true, },
    { key: "matched_rate",      name: "matched_rate (%)",    fieldName: "matched_rate",      isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 250,  isResizable: true, numberFormat: NumberFormat.Percentage,  maxDecimalPlaces: 2},
    { key: "succeeded_images",  name: "succeeded_images",    fieldName: "succeeded_images",  isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250,  isResizable: true, },
    { key: "precision",         name: "precision (%)",       fieldName: "precision",         isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 250,  isResizable: true, numberFormat: NumberFormat.Percentage,  maxDecimalPlaces: 2},
    { key: "recall",            name: "recall (%)",          fieldName: "recall",            isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250,  isResizable: true, numberFormat: NumberFormat.Percentage,  maxDecimalPlaces: 2},
    { key: "tokens_used",       name: "tokens_used",         fieldName: "tokens_used",       isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250,  isResizable: true, },
    { key: "total_words",       name: "total_words",         fieldName: "total_words",       isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250,  isResizable: true, },
    { key: "unmatched",         name: "unmatched",           fieldName: "unmatched",         isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250,  isResizable: true, },
    { key: "unmatched_rate",    name: "unmatched_rate (%)",  fieldName: "unmatched_rate",    isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250,  isResizable: true, numberFormat: NumberFormat.Percentage,  maxDecimalPlaces: 2},
    { key: "failed_images",     name: "failed_images",       fieldName: "failed_images",     isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 250,  isResizable: true, },
    { key: "failed_rate",       name: "failed_rate (%)",     fieldName: "failed_rate",       isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 250,  isResizable: true, numberFormat: NumberFormat.Percentage,  maxDecimalPlaces: 2},
];

const TYPE_OPTIONS: IDropdownOption[] = [
    { key: "general", text: "general" },
    { key: "general_skip_failed", text: "general_skip_failed" },
    { key: "general_skip_failed_1", text: "general_skip_failed_1" },
    { key: "general_skip_failed_2", text: "general_skip_failed_2" },
    { key: "general_skip_failed_3", text: "general_skip_failed_3" },
    { key: "general_skip_failed_4", text: "general_skip_failed_4" },
];

interface LargeModelItem extends LargeModelSimilarityItem {
    succeeded_images: number;
    failed_images: number;
    failed_rate: number;
}

interface LargeModelDisplayItem extends LargeModelItem {
    category: string;
    datasetName: string;
}

interface LargeModelResult {
    [category: string]: {
        unordered_word_match_rate: {
            [matchRateType: string]: LargeModelItem;
        };
    };
}

export interface IProps extends ICommonProps {}

interface IState extends ICommonState<LargeModelResult> {
    dataItems: IDataItem<LargeModelResult>[];
    selectedMatchRateType: string;
    linkData?: any;
}

export class LargeModelOverview extends CommonView<
    IProps,
    IState,
    LargeModelResult
> {
    constructor(props: IProps) {
        super(props);
        const matchDatasetVersion =
            store.getState().settingReducer.matchDatasetVersion;

        this._onItemInvoked = this._onItemInvoked.bind(this);

        this.state = {
            dataItems: [],
            expandItem: new Map<number, boolean>(),
            matchDatasetVersion: matchDatasetVersion,
            selectedMatchRateType: TYPE_OPTIONS[0].key.toString(),
            viewType: store.getState().globalReducer.viewType,
        };

        store.dispatch(
            updateStateAction({
                columns: OVERVIEW_COLUMNS,
            })
        );
    }

    render(): React.ReactNode {
        const { selectedMatchRateType, viewType } = this.state;

        return (
            <>
                <Stack horizontal style={{ marginTop: "20px" }}>
                    <Label>Rate Type&nbsp;:&nbsp;</Label>
                    <Dropdown
                        selectedKey={selectedMatchRateType}
                        options={TYPE_OPTIONS}
                        styles={{
                            dropdown: {
                                width: 200,
                            },
                        }}
                        onChange={(
                            _: React.FormEvent<HTMLDivElement>,
                            option?: IDropdownOption
                        ) => {
                            if (
                                option &&
                                selectedMatchRateType !== option.key
                            ) {
                                this.setState({
                                    selectedMatchRateType:
                                        option.key.toString(),
                                });
                            }
                        }}
                    ></Dropdown>
                </Stack>
                <FullScreen>
                    {viewType === "Table" && (
                        <div
                            style={{ height: "100%", overflow: "hidden auto" }}
                        >
                            {this._renderAsTable()}
                        </div>
                    )}

                    {viewType === "Chart" && (
                        <div
                            style={{ height: "100%", overflow: "hidden auto" }}
                        >
                            {this._renderAsChart()}
                        </div>
                    )}
                </FullScreen>
            </>
        );
    }

    componentDidMount() {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                columns: OVERVIEW_COLUMNS,
                saveKey: `${
                    this.props.saveSetKey ??
                    `${store.getState().globalReducer.workSpace}_${
                        Typename.LargeModelMetrics
                    }_Overivew`
                }`,
            })
        );
    }

    queryMetricsResult(): void {
        this._queryMetricsResult("overall_similarity_scores.json");
    }

    exportAction = () => {
        exportGeneralMetrics(
            this.exportData,
            OVERVIEW_COLUMNS,
            "LargeModelOverview"
        );
    };

    private _renderAsChart() {
        const { matchDatasetVersion } = this.state;
        const data = this._prepareRenderData(matchDatasetVersion!);

        const chartElementArr = data.map(([datasetName, groupData], index) => {
            datasetName =
                datasetName.slice(0, datasetName.indexOf(":")) +
                "    Dataset: " +
                datasetName.slice(
                    datasetName.indexOf(":") + 1,
                    datasetName.length
                );
            const chartData = groupData.map(([category, transformedItems]) => {
                const series = transformedItems.map(
                    (transformedItem, tIndex) => {
                        const focusOnData = isNaN(
                            transformedItem.renderItem.matched_rate
                        )
                            ? 0
                            : transformedItem.renderItem.matched_rate;
                        return {
                            key: `series_${tIndex}`,
                            data: focusOnData,
                            color: getColorByIndex(tIndex),
                            legend:
                                transformedItem.data?.recordDetail.name ?? "",
                        };
                    }
                );
                return {
                    name: category,
                    series: series,
                };
            });

            return (
                <Fragment key={`chart_${datasetName}`}>
                    <DocumentCard
                        key={`chart_${datasetName}`}
                        className="overview__card"
                        type={DocumentCardType.compact}
                    >
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                className="overview__title"
                                title={datasetName}
                            />
                            <VerticalBarChart
                                id={"root" + index.toString()}
                                height={600}
                                data={chartData}
                                isDarkTheme={this.props.isDarkTheme}
                            ></VerticalBarChart>
                        </DocumentCardDetails>
                    </DocumentCard>
                </Fragment>
            );
        });

        return chartElementArr;
    }

    private _renderAsTable() {
        const { records } = this.props;
        const { selectedColumns, matchDatasetVersion, expandItem } = this.state;

        let columnList: any[] = OVERVIEW_COLUMNS;
        const columns: TableColumn[] = columnList.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        const data = this._prepareRenderData(matchDatasetVersion!);
        this.exportData = data;

        const tableElementArr = data.map(([datasetName, groupData], index) => {
            datasetName =
                datasetName.slice(0, datasetName.indexOf(":")) +
                "    Dataset: " +
                datasetName.slice(
                    datasetName.indexOf(":") + 1,
                    datasetName.length
                );

            const tableDataDict = new Map<
                string,
                (LargeModelDisplayItem | null)[]
            >();

            groupData.forEach(([_, transformedItems]) => {
                const renderItems = transformedItems.map(
                    (transformedItem) => transformedItem.renderItem
                );

                tableDataDict.set(_, renderItems);
            });

            return (
                <div className="overview__detail" key={`table_${datasetName}`}>
                    {this.props.alwaysShow ? (
                        <TableList
                            evalDataCount={records.length}
                            evalData={tableDataDict}
                            columns={columns}
                            downloadTableTitle={datasetName}
                            isDarkTheme={this.props.isDarkTheme}
                            tableTitle={""}
                            onItemInvoked={this._onItemInvoked}
                            hideHeader
                        />
                    ) : (
                        <ExpandCard
                            text={datasetName}
                            expandAll={
                                expandItem?.get(index) ??
                                store.getState().globalReducer.expandAll
                            }
                            onExpandChange={(expand: boolean) => {
                                expandItem?.set(index, expand);
                                this.setState({ expandItem: expandItem }, () =>
                                    this._expandCountChange(data.length)
                                );
                            }}
                            isDarkTheme={this.props.isDarkTheme}
                        >
                            <TableList
                                evalDataCount={records.length}
                                evalData={tableDataDict}
                                columns={columns}
                                downloadTableTitle={datasetName}
                                isDarkTheme={this.props.isDarkTheme}
                                tableTitle={""}
                                onItemInvoked={this._onItemInvoked}
                                hideHeader
                            />
                        </ExpandCard>
                    )}
                </div>
            );
        });

        return tableElementArr;
    }

    private _prepareRenderData(matchDatasetVersion: boolean) {
        const { selectedMatchRateType } = this.state;

        const allDatasetNames = this._getLanguageList(matchDatasetVersion);
        const datasetNames = this._filterDatasetsForReport(allDatasetNames);

        const data = datasetNames.map((datasetName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find(
                    (item) =>
                        item.recordIndex === recordIndex &&
                        (matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName ===
                              datasetName
                            : item.recordDetail.dataset.displayName ===
                              datasetName)
                );

                return item;
            });

            const categories = Array.from(
                new Set(
                    items.flatMap((item) =>
                        item?.metrics ? Object.keys(item.metrics) : []
                    )
                )
            );

            const groupData = categories.map((category) => {
                const transformedItems = items.map((item) => {
                    let largeModelDisplayItem: LargeModelDisplayItem = {
                        datasetName: datasetName,
                        category: category,
                        f1_score: NaN,
                        f2_score: NaN,
                        failed_images: NaN,
                        failed_rate: NaN,
                        matched: NaN,
                        matched_rate: NaN,
                        precision: NaN,
                        recall: NaN,
                        succeeded_images: NaN,
                        tokens_used: NaN,
                        total_words: NaN,
                        unmatched: NaN,
                        unmatched_rate: NaN,
                    };

                    if (item && item.metrics && item.metrics[category]) {
                        const largeModelItem =
                            item.metrics[category].unordered_word_match_rate[
                                selectedMatchRateType
                            ];

                        if (largeModelItem) {
                            largeModelDisplayItem = {
                                datasetName: datasetName,
                                category: category,
                                ...largeModelItem,
                            };
                        }
                    }

                    return {
                        data: item,
                        renderItem: largeModelDisplayItem,
                    } as TransformedDataItem<
                        IDataItem<LargeModelResult>,
                        LargeModelDisplayItem
                    >;
                });

                return [category, transformedItems] as [
                    string,
                    TransformedDataItem<
                        IDataItem<LargeModelResult>,
                        LargeModelDisplayItem
                    >[]
                ];
            });

            return [datasetName, groupData] as [
                string,
                [
                    string,
                    TransformedDataItem<
                        IDataItem<LargeModelResult>,
                        LargeModelDisplayItem
                    >[]
                ][]
            ];
        });

        return data;
    }

    private deepLinkHandler(key: string, linkData: any): void {
        if (this.props.setSelectedSubPivot) {
            this.props.setSelectedSubPivot(key, linkData);
        }
    }

    private _onItemInvoked(item: any, index?: number | undefined): void {
        const values = item as any[];
        if (values?.length >= 2) {
            const displayItemsArr = values[1] as LargeModelDisplayItem[];
            if (displayItemsArr?.length > 0) {
                let linkData: { [key: string]: string | undefined } = {};
                linkData.toSelectLanguage = displayItemsArr[0].datasetName;
                linkData.toSelectCategory = displayItemsArr[0].category;

                this.deepLinkHandler &&
                    this.deepLinkHandler("ByImage", linkData);
            }
        }
    }
}
