import React from "react";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import {
    DocumentCard,
    DocumentCardDetails,
    DocumentCardTitle,
    DocumentCardType,
    Label,
    Pivot,
    PivotItem,
    PivotLinkFormat,
} from "@fluentui/react";
import {
    DatasetSet,
    IDataItem,
    MetricDefinition,
    Workspaces,
} from "../../DataContract";
import { getColorByIndex, trimAll } from "../../Utils";
import {
    ColumnValueType,
    IGroupedBarChartData,
    TableColumn,
    TableList,
    VerticalBarChart,
} from "../../Controls";
import _ from "lodash";
import { store } from "../../../store";
import { IPivotItemDarkStyles, IPivotItemStyles } from "./CommonStyle";
import "./ImageMetrics.scss";
import { updateStateAction } from "../../../store/reducers/setting";
import { FullScreen } from "./FullScreen";
import { HDSAView } from "./HDSAView";
import { NoDataTip } from "../../Controls/NoDataTip";

export interface IBarChartMetricsProps extends ICommonProps {
    saveSetKey: string;
    selectedDefinition?: string;
    onDefinitionChange?: (definition: string) => void;
}

interface IState<T> extends ICommonState<T> {
    dataItems: IDataItem<T>[];
    selectedCrossLanguages?: string[];
    selectedPivot?: string;
    target?: string;
}

export abstract class BarChartMetricsBasePage<T> extends CommonView<
    IBarChartMetricsProps,
    IState<T>,
    T
> {
    protected compatibleWithOldData: ((data: any) => void) | undefined;
    private allColumnsMap = new Map<string, TableColumn[]>();
    private selectedColumnsMap = new Map<string, string[]>();

    abstract get MetricDefinitionArray(): MetricDefinition[];
    abstract queryMetricsResult(): void;

    percentageKey: string[] = [];

    constructor(prop: IBarChartMetricsProps) {
        super(prop);
        this._loadDefaultColumns = this._loadDefaultColumns.bind(this);

        this.state = {
            dataItems: [],
            viewType: store.getState().globalReducer.viewType,
            selectedPivot: undefined,
            matchDatasetVersion: true,
        };
        store.dispatch(
            updateStateAction({
                matchDatasetVersion: true,
            })
        );
    }

    public render(): React.ReactNode {
        const { viewType } = this.state;
        return (
            <div className="overview">
                <FullScreen>
                    {viewType === "Chart" && this._renderAsChart()}
                    {viewType === "Table" && (
                        <div
                            style={{ height: "100%", overflow: "hidden auto" }}
                        >
                            {this._renderAsTable()}
                        </div>
                    )}
                </FullScreen>
            </div>
        );
    }

    public componentDidMount(): void {
        super.componentDidMount();
        this._loadDefaultColumns();
    }

    protected renderSingleChart(
        id: string,
        chartData: IGroupedBarChartData[],
        title: string
    ) {
        return (
            <>
                <DocumentCardTitle className="overview__title" title={title} />
                <VerticalBarChart
                    id={id}
                    data={chartData}
                    height={400}
                    isDarkTheme={this.props.isDarkTheme}
                />
            </>
        );
    }

    private _renderAsChart() {
        if (this.state.dataItems.length > 0) {
            const data = this._prepareRenderChartData();

            const el = this.MetricDefinitionArray.map((definition) => {
                const jsxElementArr = data
                    .map(([datasetname, metricView], index) => {
                        if (!!metricView[definition.name].table) {
                            const subCharts =
                                this._renderChartByMetricDefinition(
                                    trimAll(definition.displayName + index),
                                    definition,
                                    metricView[definition.name]
                                );

                            if (subCharts.length > 0) {
                                return (
                                    <div key={index}>
                                        <Label>{datasetname}</Label>
                                        <div
                                            key={`barChartMetrics_${definition.name}_container`}
                                        >
                                            <DocumentCard
                                                className="overview__card"
                                                key={`barChartMetrics_${definition.name}`}
                                                type={DocumentCardType.compact}
                                            >
                                                {subCharts}
                                            </DocumentCard>
                                        </div>
                                    </div>
                                );
                            }
                        }

                        return null;
                    })
                    .filter((element) => element);

                return jsxElementArr && jsxElementArr.length > 0 ? (
                    <PivotItem
                        key={definition.name}
                        headerText={definition.displayName}
                        itemKey={definition.name}
                        style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {jsxElementArr}
                    </PivotItem>
                ) : undefined;
            }).filter((v) => v);

            return (
                <>
                    {el.length > 0 || this.workSpace === Workspaces.OcrPod ? (
                        <Pivot
                            className="pivotContainer displayFlex"
                            linkFormat={PivotLinkFormat.links}
                            defaultSelectedKey={this.props.selectedDefinition}
                            onLinkClick={this.onPivotItemChanged}
                            styles={
                                this.props.isDarkTheme
                                    ? IPivotItemDarkStyles
                                    : IPivotItemStyles
                            }
                        >
                            {el}
                            {this.renderHDSA()}
                        </Pivot>
                    ) : (
                        <NoDataTip>No General Metrics Data</NoDataTip>
                    )}
                </>
            );
        }
    }

    private renderHDSA = () => {
        return this.workSpace === Workspaces.OcrPod ? (
            <PivotItem key={"hdsa"} headerText={"HDSA"} itemKey={"hdsa"}>
                <HDSAView
                    dataItems={this.state.dataItems}
                    records={this.props.records}
                    viewType={this.state.viewType}
                    onItemInvoked={this.onItemInvoked}
                />
            </PivotItem>
        ) : null;
    };

    renderAsOverview = (data: any[][], definitionName: string) => {
        return <></>;
    };

    onItemInvoked = (data: any) => {};

    private _renderAsTable() {
        if (this.state.dataItems.length > 0) {
            const { selectedColumns, selectedPivot } = this.state;
            const data = this._prepareRenderTableData();
            const el = this.MetricDefinitionArray.map((definition) => {
                const items = data
                    .map(([datasetname, metricView]) => {
                        if (!!metricView[definition.name]) {
                            const evalData = metricView[definition.name] as Map<
                                string,
                                any[]
                            >;

                            const selectColumnsInPivot =
                                selectedPivot === definition.name
                                    ? selectedColumns
                                    : this.selectedColumnsMap.get(
                                          definition.name
                                      );

                            const allColumns = this.allColumnsMap.get(
                                definition.name
                            );
                            if (
                                evalData &&
                                evalData.size > 0 &&
                                allColumns !== undefined
                            ) {
                                let displayColumns = allColumns;
                                if (
                                    selectColumnsInPivot &&
                                    selectColumnsInPivot.length > 0
                                ) {
                                    displayColumns = allColumns.filter((col) =>
                                        selectColumnsInPivot.includes(col.key)
                                    );
                                }

                                return [
                                    datasetname,
                                    evalData,
                                    displayColumns,
                                    definition,
                                ];
                            }
                        }

                        return null;
                    })
                    .filter((element) => element);

                return items && items.length > 0 ? (
                    <PivotItem
                        key={definition.name}
                        headerText={definition.displayName}
                        itemKey={definition.name}
                        style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {this.renderAsOverview(data, definition.name)}
                        {items.map((item) => this.renderEvelData(item))}
                    </PivotItem>
                ) : undefined;
            }).filter((v) => v);

            return (
                <>
                    {el.length > 0 || this.workSpace === Workspaces.OcrPod ? (
                        <Pivot
                            className="pivotContainer displayFlex"
                            linkFormat={PivotLinkFormat.links}
                            onLinkClick={this._onTablePivotItemChanged}
                            defaultSelectedKey={this.props.selectedDefinition}
                            styles={
                                this.props.isDarkTheme
                                    ? IPivotItemDarkStyles
                                    : IPivotItemStyles
                            }
                        >
                            {el}
                            {this.renderHDSA()}
                        </Pivot>
                    ) : (
                        <NoDataTip>No General Metrics Data</NoDataTip>
                    )}
                </>
            );
        }
    }
    renderEvelData = (item: any) => {
        const [datasetname, evalData, displayColumns, definition] = item;
        return (
            <div key={datasetname}>
                <Label>{datasetname}</Label>
                <TableList
                    evalData={evalData}
                    evalDataCount={this.props.records.length}
                    columns={displayColumns}
                    downloadTableTitle={datasetname}
                    disableFreezeHeader
                    hideHeader
                    onItemInvoked={(item: any) => {
                        this.onItemInvoked([
                            definition.name,
                            datasetname,
                            item[0],
                        ]);
                    }}
                />
            </div>
        );
    };

    getName = (name: string) => {
        if (this.percentageKey.includes(name)) {
            return `${name}%`;
        }
        return name;
    };

    getValue = (key: string, value: any) => {
        if (this.percentageKey.includes(key) && value && !isNaN(value)) {
            return Number(Number(value) * 100).toFixed(2);
        }
        return value;
    };

    private _renderChartByMetricDefinition(
        id: string,
        definition: MetricDefinition,
        metrics: any
    ) {
        let subCharts: JSX.Element[] = [];
        if (!!metrics) {
            Object.entries(metrics.table as any).forEach(
                ([name, metricsValue], index) => {
                    const defProp = definition.props.find(
                        (p) => p.fieldName === name
                    );

                    const chartData: IGroupedBarChartData[] = [];
                    const chartField = Object.keys(metricsValue as any);
                    chartField.forEach((prop) => {
                        const values = (metricsValue as any)[prop] as any[];
                        if (values && values.some((value) => !isNaN(value))) {
                            const table = (values as any[]).map(
                                (value, recordIndex) => {
                                    return {
                                        key: `metricDef_${id}_${recordIndex}`,
                                        data: value
                                            ? Number(Number(value).toFixed(2))
                                            : (0 as number),
                                        color: getColorByIndex(recordIndex),
                                        legend: this.props.records[recordIndex]
                                            .name,
                                    };
                                }
                            );

                            chartData.push({
                                name: prop,
                                series: table,
                            });
                        }
                    });

                    if (chartData && chartData.length > 0) {
                        const subChart = (
                            <DocumentCardDetails
                                key={`barChartMetrics_${definition.name}_${name}`}
                            >
                                {this.renderSingleChart(
                                    id + index,
                                    chartData,
                                    defProp ? defProp.displayName : name
                                )}
                            </DocumentCardDetails>
                        );

                        subCharts.push(subChart);
                    }
                }
            );
        }

        return subCharts;
    }

    private _prepareRenderChartData() {
        const { records } = this.props;

        const datasets = Array.from(
            new DatasetSet(records.flatMap((r) => r.getDatasets()))
        );
        const datasetFullNames = Array.from(
            new Set(datasets.map((dataset) => dataset.fullName))
        );

        const data = datasetFullNames.map((fullName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find((item) => {
                    return (
                        item.recordIndex === recordIndex &&
                        item.recordDetail.dataset.fullName === fullName
                    );
                });
                return item;
            });

            const groups = this.MetricDefinitionArray.map(
                (definition) => definition.name
            );

            const categories = groups.map((group) => {
                const groupValues = items
                    .map((item) => {
                        const metric = item?.metrics as any;
                        return metric && metric[group];
                    })
                    .filter((v) => v);

                return Array.from(
                    new Set(groupValues.flatMap((value) => Object.keys(value)))
                );
            });

            const tableData: any = {};
            groups.forEach((group, groupId) => {
                let data = [] as any[];
                let tableArr: any = {};
                items.forEach((item) => {
                    if (!!item && (item.metrics as any)[group]) {
                        const { metrics } = item;
                        categories[groupId].forEach((category) => {
                            if (!tableArr[category]) {
                                tableArr[category] = {};
                            }
                            let metricItem = (metrics as any)[group]
                                ? (metrics as any)[group][category]
                                : [];

                            this.compatibleWithOldData &&
                                this.compatibleWithOldData(metricItem);

                            for (const key in metricItem) {
                                if (key.startsWith("#") || key === "count") {
                                    continue;
                                }

                                if (!!!tableArr[category][key]) {
                                    tableArr[category][key] = new Array(
                                        records.length
                                    ).fill(undefined);
                                }

                                tableArr[category][key][item.recordIndex] =
                                    this.getValue(key, metricItem[key]);
                            }
                        });
                    }
                    data.push(item);
                });
                const categoryItems = {
                    data: data,
                    table: tableArr,
                };
                tableData[group] = categoryItems;
            });
            return [fullName, tableData];
        });
        return data;
    }

    private _prepareRenderTableData() {
        const { records } = this.props;
        const { matchDatasetVersion } = this.state;

        const datasets = Array.from(
            new DatasetSet(records.flatMap((r) => r.getDatasets()))
        );
        const datasetNames = Array.from(
            new Set(
                datasets.map((dataset) =>
                    matchDatasetVersion
                        ? dataset.displayFullName
                        : dataset.displayName
                )
            )
        );

        const data = datasetNames.map((name) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find((item) => {
                    return (
                        item.recordIndex === recordIndex &&
                        (matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName === name
                            : item.recordDetail.dataset.displayName === name)
                    );
                });
                return item;
            });

            const groups = this.MetricDefinitionArray.map(
                (definition) => definition.name
            );

            const tableData: any = {};
            groups.forEach((group) => {
                const dataMap = new Map<string, any[]>();
                items.forEach((item, itemIndex) => {
                    if (!!item && (item.metrics as any)[group]) {
                        const obj = (item.metrics as any)[group];
                        Object.entries(obj).forEach(([k, v]) => {
                            let valArr = dataMap.get(k);
                            if (valArr === undefined) {
                                valArr = Array.from(
                                    { length: items.length },
                                    () => {
                                        return {} as any;
                                    }
                                );
                            }

                            const tempV: any = _.cloneDeep(v);
                            this.compatibleWithOldData &&
                                this.compatibleWithOldData(tempV);
                            for (const key of Object.keys(tempV)) {
                                tempV[key] = this.getValue(key, tempV[key]);
                            }
                            valArr[itemIndex] = tempV;
                            dataMap.set(k, valArr);
                        });
                    }
                });

                tableData[group] = dataMap;
            });

            const valueMapArr = Object.values(tableData) as Map<
                string,
                any[]
            >[];

            const isAvailable = valueMapArr.some((map) => map.size > 0);
            if (isAvailable) {
                tableData["data_count"] = items.map((item) => {
                    if (!!item && (item.metrics as any)["data_count"]) {
                        return (item.metrics as any)["data_count"];
                    } else {
                        return undefined;
                    }
                });
            }

            return [name, isAvailable ? tableData : null];
        });

        const filterData = data.filter(([_, tableData]) => tableData !== null);
        return filterData;
    }

    private _loadDefaultColumns() {
        const { saveSetKey, selectedDefinition } = this.props;
        const { selectedPivot } = this.state;
        if (
            selectedPivot === undefined &&
            this.MetricDefinitionArray &&
            this.MetricDefinitionArray.length > 0
        ) {
            this.MetricDefinitionArray.forEach((definition) => {
                const colStrArr: string[] = [];
                const defSelectedStrArr: string[] = [];
                definition.tablefields.forEach((p) => {
                    !colStrArr.includes(p) && colStrArr.push(p);
                });

                if (definition.defFields && definition.defFields.length > 0) {
                    definition.defFields.forEach((defProp) => {
                        !defSelectedStrArr.includes(defProp) &&
                            defSelectedStrArr.push(defProp);
                    });
                }

                if (colStrArr) {
                    const columns = colStrArr.map((colStr) => {
                        return {
                            key: colStr,
                            name: this.getName(colStr),
                            fieldName: colStr,
                            valueType: ColumnValueType.Number,
                            minWidth: 150,
                            maxWidth: 300,
                            maxDecimalPlaces: 3,
                            isResizable: true,
                        } as TableColumn;
                    });

                    columns.unshift({
                        key: "name",
                        name: "name",
                        fieldName: "name",
                        valueType: ColumnValueType.String,
                        minWidth: 100,
                        maxWidth: 200,
                        isResizable: true,
                        isKey: true,
                    });

                    this.allColumnsMap.set(definition.name, columns);
                    this.selectedColumnsMap.set(
                        definition.name,
                        defSelectedStrArr && defSelectedStrArr.length > 0
                            ? ["name", ...defSelectedStrArr]
                            : ["name", ...colStrArr]
                    );
                }
            });

            const hasSelectedDefiniton =
                selectedDefinition !== undefined &&
                this.MetricDefinitionArray.some(
                    (m) => m.name === selectedDefinition
                );
            const defaultPivotName = hasSelectedDefiniton
                ? selectedDefinition!
                : this.MetricDefinitionArray[0].name;
            this.setState(
                {
                    selectedPivot: defaultPivotName,
                },
                () => {
                    const selectedColumns =
                        this.selectedColumnsMap.get(defaultPivotName);
                    const allColumns = this.allColumnsMap.get(defaultPivotName);

                    store.dispatch(
                        updateStateAction({
                            saveKey: `${saveSetKey}_${defaultPivotName}`,
                            selectedItems: selectedColumns,
                            columns: allColumns,
                        })
                    );
                }
            );
        }
    }
    onPivotItemChanged = (item?: PivotItem) => {
        const { onDefinitionChange } = this.props;
        if (onDefinitionChange) {
            onDefinitionChange(item?.props.itemKey!);
        }
    };

    _onTablePivotItemChanged = (item?: PivotItem) => {
        if (item && item.props.itemKey) {
            this.onPivotItemChanged(item);
            const itemKey = item.props.itemKey;
            if (itemKey === this.state.selectedPivot) {
                return;
            }

            const { saveSetKey } = this.props;
            const { selectedColumns, selectedPivot: currentPivot } = this.state;

            if (currentPivot && selectedColumns !== undefined) {
                this.selectedColumnsMap.set(currentPivot, selectedColumns);
            }

            const allColumns = this.allColumnsMap.get(itemKey);
            const selectedColumnsOfNewPivot =
                this.selectedColumnsMap.get(itemKey);

            this.setState({
                target: undefined,
                selectedPivot: itemKey,
                selectedColumns: selectedColumnsOfNewPivot,
            });

            store.dispatch(
                updateStateAction({
                    columns: allColumns,
                    saveKey: `${saveSetKey}_${itemKey}`,
                    selectedItems: selectedColumnsOfNewPivot,
                })
            );
        }
    };
}
