import React from "react";
import { Record, Typename, Workspaces } from "../../DataContract";
import { GeneralMetrics } from "../../Views/Ocr/GeneralMetrics";
import { EntityMetrics } from "../../Views/Ocr/EntityMetrics";
import { LatencyMetrics } from "../../Views/Latency/LatencyMetrics";
import { CharMetrics } from "../../Views/Ocr/CharMetrics";
import { HighlightMetrics } from "../../Views/Ocr/HighlightMetrics";
import { DetectionMetrics } from "../../Views/Ocr/DetectionMetrics";
import { MetricsAnalysis } from "../../Views/Ocr/OcrDataContract";
import { WordAlignMetrics } from "../../Views/Ocr/WordAlignMetrics";
import { LargeModelMetrics } from "../../Views/Ocr/LargeModelMetrics";

interface IState {
    detectionMetricsAnalysisOn: boolean;
    generalMetricsAnalysisOn: boolean;
}

interface IProps {
    records: Record[];
    selectedKey: string;
    isDarkTheme?: boolean;
}

export class OcrPage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            generalMetricsAnalysisOn: false,
            detectionMetricsAnalysisOn: false,
        };

        this._updateAnalysisState = this._updateAnalysisState.bind(this);
    }

    public render() {
        const { records, selectedKey } = this.props;

        return (
            <>
                {selectedKey === "highlightMetrics" && (
                    <HighlightMetrics
                        saveSetKey={`${Workspaces.Ocr}_${Typename.HighlightMetrics}`}
                        records={records}
                        isDarkTheme={this.props.isDarkTheme}
                    ></HighlightMetrics>
                )}
                {selectedKey === "generalMetrics" && (
                    <GeneralMetrics
                        saveSetKey={`${Workspaces.Ocr}_${Typename.GeneralMetrics}`}
                        records={records}
                        analysisOn={this.state.generalMetricsAnalysisOn}
                        updateAnalysisState={this._updateAnalysisState}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}
                {selectedKey === "entityMetrics" && (
                    <EntityMetrics
                        saveSetKey={`${Workspaces.Ocr}_${Typename.EntityMetrics}`}
                        records={records}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}
                {selectedKey === "latencyMetrics" && (
                    <LatencyMetrics
                        saveSetKey={`${Workspaces.Ocr}_${Typename.LatencyMetrics}`}
                        records={records}
                        defaultCrossLanguage="Total"
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}
                {selectedKey === "langMetrics" && (
                    <CharMetrics
                        saveSetKey={`${Workspaces.Ocr}_${Typename.CharMetrics}`}
                        records={records}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}
                {selectedKey === "detectionMetrics" && (
                    <DetectionMetrics
                        saveSetKey={`${Workspaces.Ocr}_${Typename.DetectionMetrics}`}
                        records={records}
                        analysisOn={this.state.detectionMetricsAnalysisOn}
                        updateAnalysisState={this._updateAnalysisState}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}
                {selectedKey === "wordAlignMetrics" && (
                    <WordAlignMetrics
                        saveSetKey={`${Workspaces.Ocr}_${Typename.WordAlignMetrics}`}
                        records={records}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}
                {selectedKey === "largeModelMetrics" && (
                    <LargeModelMetrics
                        saveSetKey={`${Workspaces.Ocr}_${Typename.LargeModelMetrics}`}
                        records={records}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                )}
            </>
        );
    }

    private _updateAnalysisState(
        metricsAnalysis: MetricsAnalysis,
        isOn: boolean
    ): void {
        switch (metricsAnalysis) {
            case MetricsAnalysis.General: {
                this.setState({ generalMetricsAnalysisOn: isOn });
                break;
            }
            case MetricsAnalysis.Detection: {
                this.setState({ detectionMetricsAnalysisOn: isOn });
                break;
            }
        }
    }
}
