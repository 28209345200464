import { ChoiceGroup, IChoiceGroupOption, Stack } from "@fluentui/react";
import React from "react";
import { store } from "../../../store";
import "../Pages.scss";
import { updateViewTypeAction } from "../../../store/reducers";
import { SubscribeHoc } from "./SubscribeHoc";

const CHOICE_OPTIONS: IChoiceGroupOption[] = [
    { key: "Chart", text: "View as Chart" },
    { key: "Table", text: "View as Table" },
];

const SHOW_OPTIONS = [
    "basicTableMetrics",
    "highlightMetrics",
    "generalMetrics",
    "latencyMetrics",
    "checkboxLatencyMetrics",
    "overviewInvoiceMetrics",
    "overviewKvpMetrics",
    "ocrKvpLatencyMetrics",
    "ocrTableLatencyMetrics",
    "ocrPodGeneralMetrics",
    "ocrPodLatencyMetrics",
    "ocrFigureLatencyMetrics",
    "overviewQueryFieldMetrics",
    "largeModelMetrics",
];

interface IProps {
    selectedKey: string;
    selectSubPivot: string;
    viewType: string;
    releaseTestselectSubPivot: string;
}
interface IState {}

export class ViewTypeComponent extends React.Component<IProps, IState> {
    constructor(prop: any) {
        super(prop);
        this.state = {};
    }

    public render() {
        const {
            selectedKey,
            selectSubPivot,
            viewType,
            releaseTestselectSubPivot,
        } = this.props;

        const show =
            (SHOW_OPTIONS.includes(selectedKey) ||
                (selectedKey === "accurReleaseMetrics" &&
                    ["generalMetrics", "latencyMetrics"].includes(
                        releaseTestselectSubPivot
                    ))) &&
            selectSubPivot === "Overview";

        return (
            <>
                {show && (
                    <Stack horizontal verticalAlign="center">
                        <span>|</span>
                        <ChoiceGroup
                            styles={{
                                flexContainer: {
                                    div: {
                                        marginTop: "0px",
                                    },
                                    span: {
                                        width: "auto",
                                    },
                                },
                            }}
                            className="viewOptions"
                            options={CHOICE_OPTIONS}
                            selectedKey={viewType}
                            onChange={(_event, option) =>
                                store.dispatch(
                                    updateViewTypeAction(option?.key)
                                )
                            }
                        />
                    </Stack>
                )}
            </>
        );
    }
}

const ViewTypeButton = SubscribeHoc(ViewTypeComponent);
export default ViewTypeButton;
