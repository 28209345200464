import React from "react";
import { store } from "../../../store";
import { Pivot, PivotItem, PivotLinkFormat } from "@fluentui/react";
import { updateSelectSubpivoteAction } from "../../../store/reducers";
import { LargeModelOverview, IProps } from "./LargeModelOverview";
import { Typename, Workspaces } from "../../DataContract";
import { LargeModelByImage } from "./LargeModelByImage";

interface IState {
    selectedPivot: string;
}

export class LargeModelMetrics extends React.Component<IProps, IState> {
    private linkData?: any;

    constructor(props: IProps) {
        super(props);

        this.onPivotItemChanged = this.onPivotItemChanged.bind(this);
        this.state = {
            selectedPivot: "Overview",
        };
    }

    render(): React.ReactNode {
        const { records, isDarkTheme } = this.props;
        return (
            <div className="overview">
                <Pivot
                    onLinkClick={this.onPivotItemChanged}
                    linkFormat={PivotLinkFormat.links}
                    selectedKey={this.state.selectedPivot}
                    className="displayFlex"
                    styles={{
                        itemContainer: {
                            height: "100%",
                            overflow: "hidden",
                        },
                    }}
                >
                    <PivotItem
                        headerText="Overview"
                        className="displayFlex"
                        itemKey="Overview"
                    >
                        <LargeModelOverview
                            saveSetKey={`${Workspaces.Ocr}_${Typename.LargeModelMetrics}_Overivew`}
                            records={records}
                            isDarkTheme={isDarkTheme}
                            setSelectedSubPivot={this.setSelectedSubPivot}
                        />
                    </PivotItem>
                    <PivotItem
                        headerText="By Image"
                        className="displayFlex"
                        itemKey="ByImage"
                    >
                        <LargeModelByImage
                            records={records}
                            saveSetKey={`${Workspaces.Ocr}_${Typename.LargeModelMetrics}_ByImage`}
                            linkData={this.linkData}
                            isDarkTheme={isDarkTheme}
                        />
                    </PivotItem>
                </Pivot>
            </div>
        );
    }

    onPivotItemChanged(item?: PivotItem): any {
        if (item?.props.itemKey === this.state.selectedPivot) {
            return;
        }
        this.linkData = undefined;
        this.changeSelectedSubPivot(item?.props.itemKey!);
    }

    setSelectedSubPivot = (selectPivot: string, linkData?: any) => {
        this.linkData = linkData;
        this.changeSelectedSubPivot(selectPivot);
    };

    changeSelectedSubPivot = (selectPivot: string) => {
        this.setState({
            selectedPivot: selectPivot,
        });

        store.dispatch(updateSelectSubpivoteAction(selectPivot));
    };
}
