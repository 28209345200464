import * as React from "react";
import "./imageVisualizer.scss";
import "./LargeModelImageVisualizer.scss";
import _ from "lodash";
import { isDevEnvironment } from "../../Utils/Utils";
import {
    VisualizerBase,
    VisualizerProps,
    VisualizerState,
} from "./VisualizerBase";
import {
    ChoiceGroup,
    FontIcon,
    getTheme,
    Label,
    mergeStyles,
    Stack,
} from "@fluentui/react";
import { Consumer } from "../../Layout";
import { LargeModelMetric } from "../../Views/Ocr/LargeModelByImage";
import ImageMapper from "./ImageMapper";

const theme = getTheme();
const DEFAULT_SELECTED_EXP_INDEX = 0;
const pickExpIcon = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
});

interface IProps extends VisualizerProps {
    fileId: string;
    onLoadVisualizer: (
        fileId?: string,
        index?: number
    ) => LargeModelMetric | undefined;
    evalList?: [string, LargeModelMetric][];
    experiments?: string[];
    onDismiss?: (fileId: any) => void;
}

interface IState extends VisualizerState {
    fileId: string;
    fileInfo: LargeModelMetric | undefined;
    selectedExpIndex: number;
    currentPage?: number;
}

export class LargeModelImageVisualizer extends VisualizerBase<IProps, IState> {
    protected refreshCache(paramObj?: any): void {}
    constructor(props: IProps) {
        super(props);

        const largeModelMetric = props.onLoadVisualizer(
            props.fileId,
            DEFAULT_SELECTED_EXP_INDEX
        );
        this.onPageChange = this.onPageChange.bind(this);
        this.debounceOnPageChange = _.debounce(
            this.debounceOnPageChange.bind(this),
            500
        );

        this.state = {
            fileId: props.fileId,
            fileInfo: largeModelMetric,
            selectedExpIndex: DEFAULT_SELECTED_EXP_INDEX,
            fileUrl: "",
            currentPage: props.clickNumber ?? 0,
        };
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState) {
        const { onLoadVisualizer } = this.props;
        const { fileId, selectedExpIndex } = this.state;
        if (
            (prevState.currentPage !== this.state.currentPage &&
                prevState.fileId !== this.state.fileId) ||
            prevState.selectedExpIndex !== this.state.selectedExpIndex
        ) {
            this.setState({
                fileInfo: onLoadVisualizer(fileId, selectedExpIndex),
            });
        }
    }

    protected renderVisualizer(): JSX.Element {
        const { fileId, fileInfo, selectedExpIndex } = this.state;
        const { experiments } = this.props;

        return (
            <Consumer>
                {(value) => (
                    <div className="viewer_par">
                        <div className="viewer">
                            <div className="viewer__container" key={fileId}>
                                <Stack horizontal className="exhibitionArea">
                                    {fileInfo?.imageUrl && (
                                        <div className="imageContainer">
                                            <ImageMapper
                                                height={700}
                                                src={
                                                    isDevEnvironment()
                                                        ? "/ViewerDemo/bizcard006929.jpg"
                                                        : fileInfo?.imageUrl
                                                }
                                            />
                                        </div>
                                    )}
                                    {(fileInfo === undefined ||
                                        fileInfo.imageUrl === "") && (
                                        <div className="emptyContainer"></div>
                                    )}

                                    <div className="settingContainer">
                                        <Stack
                                            horizontal
                                            verticalAlign="center"
                                        >
                                            <FontIcon
                                                aria-label="Compass"
                                                iconName="SpeedHigh"
                                                className={pickExpIcon}
                                                style={{
                                                    color: value
                                                        ? theme.palette.white
                                                        : theme.palette.black,
                                                }}
                                            />
                                            <Label
                                                styles={{
                                                    root: {
                                                        marginLeft:
                                                            "10px!important",
                                                    },
                                                }}
                                            >
                                                Pick evaluation experiment:
                                            </Label>
                                        </Stack>

                                        <ChoiceGroup
                                            style={{
                                                position: "relative",
                                                left: "-10px",
                                            }}
                                            options={experiments?.map(
                                                (value, index) => ({
                                                    key: `exp_${index}`,
                                                    text: value,
                                                    id: index.toString(),
                                                })
                                            )}
                                            selectedKey={`exp_${selectedExpIndex}`}
                                            onChange={(_event, option) => {
                                                option &&
                                                    this.setState({
                                                        selectedExpIndex:
                                                            Number(option.id),
                                                    });
                                            }}
                                        />
                                    </div>
                                </Stack>
                                <Stack>
                                    {fileInfo && (
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <Label>gt:</Label>
                                                    </th>
                                                    <th>
                                                        <Label>reco:</Label>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Label>
                                                            {this.renderTextWithLineBreak(
                                                                fileInfo?.gt
                                                            )}
                                                        </Label>
                                                    </td>
                                                    <td>
                                                        <Label>
                                                            {this.renderTextWithLineBreak(
                                                                fileInfo?.reco
                                                            )}
                                                        </Label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )}

                                    {fileInfo === undefined && (
                                        <Label className="emptyMetric">
                                            Current catagory has no
                                            corresponding metric.
                                        </Label>
                                    )}
                                </Stack>
                            </div>
                        </div>
                    </div>
                )}
            </Consumer>
        );
    }

    protected onPageChange(page: number) {
        const { evalList } = this.props;
        if (evalList && evalList.length > 0) {
            var [newFileId, newFileInfo] = evalList[page];
            this.setState({
                fileId: newFileId,
                fileInfo: newFileInfo,
                currentPage: page,
            });
            this.debounceOnPageChange(page);
        }
    }

    private renderTextWithLineBreak(text?: string) {
        if (text) {
            const paragraphs = text.split("\n");
            return (
                <>
                    {paragraphs.map((p) => (
                        <Label>{p}</Label>
                    ))}
                </>
            );
        } else {
            return <></>;
        }
    }
}
