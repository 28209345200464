import React from "react";
import { ReactNode } from "react";
import { Modal } from "@fluentui/react";
import { store } from "../../../store";
import {
    IMetrics,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";
import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import { OcrGeneralStateInSession } from "./GeneralMetrics";
import {
    ColumnValueType,
    NumberFormat,
    TableColumn,
    TableList,
} from "../../Controls";
import { TableHeader, ITableConfigurations } from "../../Controls/TableHeader";
import { FullScreen } from "../Common/FullScreen";
import { resetScrollBarOfScrollablePane } from "../../Utils";
import { updateStateAction } from "../../../store/reducers/setting";
import { LargeModelImageVisualizer } from "../../Controls/Visualizer/LargeModelImageVisualizer";
import {
    hidePrepareRenderDataLoading,
    showPrepareRenderDataLoading,
} from "../../Utils/LoadingUtil";

// prettier-ignore
const imageColumns: TableColumn[] = [
    { key: "imageId",         name: "ImageId",             fieldName:"imageId",         valueType: ColumnValueType.String,  minWidth: 400,  maxWidth: 450, isKey: true },
    { key: "f1_score",        name: "f1_score (%) ",       fieldName:"f1_score",        valueType: ColumnValueType.Number,  minWidth: 80,   maxWidth: 100, numberFormat: NumberFormat.Percentage,  maxDecimalPlaces: 2 },
    { key: "f2_score",        name: "f2_score (%)",        fieldName:"f2_score",        valueType: ColumnValueType.Number,  minWidth: 80,   maxWidth: 100, numberFormat: NumberFormat.Percentage,  maxDecimalPlaces: 2 },
    { key: "matched",         name: "matched",             fieldName:"matched",         valueType: ColumnValueType.Number,  minWidth: 80,   maxWidth: 100 },
    { key: "matched_rate",    name: "matched_rate (%)",    fieldName:"matched_rate",    valueType: ColumnValueType.Number,  minWidth: 80,   maxWidth: 100, numberFormat: NumberFormat.Percentage,  maxDecimalPlaces: 2 },
    { key: "precision",       name: "precision (%)",       fieldName:"precision",       valueType: ColumnValueType.Number,  minWidth: 80,   maxWidth: 100, numberFormat: NumberFormat.Percentage,  maxDecimalPlaces: 2 },
    { key: "recall",          name: "recall (%)",          fieldName:"recall",          valueType: ColumnValueType.Number,  minWidth: 80,   maxWidth: 100, numberFormat: NumberFormat.Percentage,  maxDecimalPlaces: 2 },
    { key: "tokens_used",     name: "tokens_used",         fieldName:"tokens_used",     valueType: ColumnValueType.Number,  minWidth: 80,   maxWidth: 100 },
    { key: "total_words",     name: "total_words",         fieldName:"total_words",     valueType: ColumnValueType.Number,  minWidth: 80,   maxWidth: 100 },
    { key: "unmatched",       name: "unmatched",           fieldName:"unmatched",       valueType: ColumnValueType.Number,  minWidth: 80,   maxWidth: 100 },
    { key: "unmatched_rate",  name: "unmatched_rate (%)",  fieldName:"unmatched_rate",  valueType: ColumnValueType.Number,  minWidth: 80,   maxWidth: 100, numberFormat: NumberFormat.Percentage,  maxDecimalPlaces: 2 }
];

export interface LargeModelSimilarityItem {
    f1_score: number;
    f2_score: number;
    matched: number;
    matched_rate: number;
    precision: number;
    recall: number;
    tokens_used: number;
    total_words: number;
    unmatched: number;
    unmatched_rate: number;
}

export interface LargeModelMetric extends LargeModelSimilarityItem {
    gt: string;
    reco: string;
    imageUrl: string;
}

interface IState extends IMetricState<LargeModelMetric> {
    selectLanguage?: string;
    selectCategory?: string;
    selectImageId?: string;
    categoryList: string[];
    clickNumber?: number;
    imageVisItems?: any[];
}

interface IProps extends IMetricProps {
    linkData?: {
        toSelectLanguage: string;
        toSelectCategory: string;
    };
    onDismiss?: (imageId: any) => void;
}

export class LargeModelByImage extends MetricsView<
    IProps,
    IState,
    LargeModelMetric
> {
    constructor(props: IProps) {
        super(props);

        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);

        let selectLanguage = props.linkData?.toSelectLanguage;
        let selectCategory = props.linkData?.toSelectCategory;

        if (selectLanguage === undefined || selectCategory === undefined) {
            const stateKey = this.getSessionStateKey(
                props.records,
                Workspaces.Ocr,
                Typename.LargeModelMetrics,
                "ByImage"
            );
            const stateStr = sessionStorage.getItem(stateKey);
            if (stateStr) {
                const sessionState = JSON.parse(
                    stateStr
                ) as OcrGeneralStateInSession;

                if (sessionState.selectLanguage) {
                    selectLanguage = sessionState.selectLanguage;
                }

                if (sessionState.selectCategory) {
                    selectCategory = sessionState.selectCategory;
                }
            }
        }

        this.state = {
            evalData: {},
            categoryList: [],
            selectLanguage: selectLanguage,
            selectCategory: selectCategory,
            matchDatasetVersion:
                store.getState().settingReducer.matchDatasetVersion,
        };
        store.dispatch(
            updateStateAction({
                columns: imageColumns,
            })
        );
    }

    render(): ReactNode {
        const { records } = this.props;
        const { evalData, selectImageId, imageVisItems, selectedColumns } =
            this.state;

        let clickNumber: number = 0;
        let columns: TableColumn[] = imageColumns.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );
        const experiments = records.map((record) => record.name);
        const tableKey = `${this.state.selectLanguage}_${this.state.selectCategory}`;
        return (
            <>
                <Modal
                    styles={{
                        main: {
                            width: "100%!important",
                            height: "100%!important",
                        },
                    }}
                    isOpen={!!selectImageId}
                    containerClassName="modal"
                    onDismiss={() =>
                        this.setState({ selectImageId: undefined })
                    }
                >
                    {!!selectImageId && (
                        <LargeModelImageVisualizer
                            fileId={selectImageId}
                            evalList={imageVisItems}
                            experiments={experiments}
                            onLoadVisualizer={(imageId, index) => {
                                let result:
                                    | LargeModelMetric
                                    | undefined
                                    | null = undefined;

                                if (
                                    imageId &&
                                    evalData[imageId] &&
                                    index !== undefined &&
                                    !Number.isNaN(index)
                                ) {
                                    result = evalData[imageId][index];
                                }

                                return result ? result : undefined;
                            }}
                            onDismiss={(imageId: string) =>
                                this.onDismiss(imageId)
                            }
                            clickNumber={this.state.clickNumber}
                        />
                    )}
                </Modal>
                <FullScreen>
                    <TableList<LargeModelMetric>
                        key={tableKey}
                        evalDataCount={this.props.records.length}
                        evalData={evalData}
                        columns={columns}
                        downloadTableTitle={tableKey}
                        isFullFilterMenu={true}
                        onItemInvoked={(item: any, index: any) => {
                            clickNumber = index;
                            const [imageId] = item;
                            this.setState({
                                selectImageId: imageId,
                                clickNumber: clickNumber,
                            });
                        }}
                        renderTableHeader={this._renderTableHeader}
                        getDisplayEvalData={(displayItems: any[]) => {
                            this.getDisplayEvalData(displayItems);
                        }}
                        isDarkTheme={this.props.isDarkTheme}
                    />
                </FullScreen>
            </>
        );
    }

    componentDidMount() {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                columns: imageColumns,
                saveKey: `${
                    this.props.saveSetKey ??
                    `${store.getState().globalReducer.workSpace}_${
                        Typename.LargeModelMetrics
                    }_ByImage`
                }`,
            })
        );
    }

    queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<LargeModelMetric>> {
        const { selectCategory } = this.state;

        return recordDetail === undefined
            ? Promise.resolve({})
            : Promise.all([
                  recordDetail.fetchMetricsWithCamelCasing<
                      IMetrics<LargeModelMetric>
                  >(metricName),
                  recordDetail.fetchMetricsWithCamelCasing<any>(
                      `${selectCategory!}/similarity_scores.json`
                  ),
                  recordDetail.dataset.fetchImageListByCategory(
                      selectCategory!
                  ),
              ])
                  .then(([records, similarity, imageList]) => {
                      showPrepareRenderDataLoading();
                      Object.entries(records).forEach(([key, val]) => {
                          records[key].gt = val.gt;
                          records[key].reco = val.reco;
                          const imageName =
                              imageList.find((image) =>
                                  image
                                      .toLowerCase()
                                      .includes(key.toLowerCase())
                              ) ?? `${key}.jpg`;
                          records[key].imageUrl =
                              recordDetail.dataset.getImageUrl(
                                  `${selectCategory}/${imageName}`
                              );

                          if (
                              similarity &&
                              "unordered_word_match_rate" in similarity &&
                              "per_image" in
                                  similarity["unordered_word_match_rate"]
                          ) {
                              const perImage =
                                  similarity["unordered_word_match_rate"][
                                      "per_image"
                                  ];
                              records[key].f1_score = perImage[key]
                                  ? (perImage[key].f1_score as number)
                                  : NaN;
                              records[key].f2_score = perImage[key]
                                  ? (perImage[key].f2_score as number)
                                  : NaN;
                              records[key].matched = perImage[key]
                                  ? (perImage[key].matched as number)
                                  : NaN;
                              records[key].matched_rate = perImage[key]
                                  ? (perImage[key].matched_rate as number)
                                  : NaN;
                              records[key].precision = perImage[key]
                                  ? (perImage[key].precision as number)
                                  : NaN;
                              records[key].recall = perImage[key]
                                  ? (perImage[key].recall as number)
                                  : NaN;
                              records[key].tokens_used = perImage[key]
                                  ? (perImage[key].tokens_used as number)
                                  : NaN;
                              records[key].total_words = perImage[key]
                                  ? (perImage[key].total_words as number)
                                  : NaN;
                              records[key].unmatched = perImage[key]
                                  ? (perImage[key].unmatched as number)
                                  : NaN;
                              records[key].unmatched_rate = perImage[key]
                                  ? (perImage[key].unmatched_rate as number)
                                  : NaN;
                          }
                      });
                      return records;
                  })
                  .finally(() => {
                      hidePrepareRenderDataLoading();
                  });
    }
    onEvaluationRecordChanged(): void {
        this._onEvaluationRecordChanged(this.state.matchDatasetVersion!);
    }

    _onEvaluationRecordChanged(matchDatasetVersion: boolean) {
        const { selectLanguage } = this.state;
        const languageList = this.getLanguageList(matchDatasetVersion);

        if (languageList.length > 0) {
            const language = selectLanguage ?? languageList[0];
            this._onOptionsChanged(
                {
                    selectLanguage: language,
                },
                matchDatasetVersion
            );
        } else {
            this.setState({
                evalData: {},
            });
        }
    }

    private _onOptionsChanged(
        newOptions: { [key: string]: string | undefined },
        matchDatasetVersion: boolean
    ) {
        let selectLanguage: any;
        let selectCategory: any;
        selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;
        selectCategory =
            "selectCategory" in newOptions
                ? newOptions["selectCategory"]
                : this.state.selectCategory;

        let categories = this.getCategoryList(
            selectLanguage!,
            matchDatasetVersion
        );
        selectCategory =
            selectCategory && categories.indexOf(selectCategory) >= 0
                ? selectCategory
                : categories[0];

        this.setState(
            {
                selectCategory: selectCategory,
                selectLanguage: selectLanguage,
                categoryList: categories,
                evalData: {},
            },
            () => {
                const { records } = this.props;
                const stateKey = this.getSessionStateKey(
                    records,
                    Workspaces.Ocr,
                    Typename.LargeModelMetrics,
                    "ByImage"
                );
                const stateInSession: OcrGeneralStateInSession = {
                    selectLanguage: selectLanguage,
                    selectCategory: selectCategory,
                };

                const stateStr = JSON.stringify(stateInSession);
                sessionStorage.setItem(stateKey, stateStr);

                this._onQueryButtonClicked();
            }
        );
    }

    private _onQueryButtonClicked = () => {
        const { records } = this.props;
        const { selectLanguage, selectCategory, matchDatasetVersion } =
            this.state;
        if (records.length > 0) {
            const details = this.filterRecordDetails(
                selectLanguage!,
                matchDatasetVersion!
            );
            this.showEvaluationResult(
                details,
                `${selectCategory!}/similarity_gt_reco_pairs.json`
            );
        }
    };

    private _renderTableHeader(): JSX.Element {
        const {
            categoryList,
            selectLanguage,
            selectCategory,
            matchDatasetVersion,
        } = this.state;
        let languages = this.getLanguageList(matchDatasetVersion!);
        let imageConfigurations: ITableConfigurations = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLanguage ?? languages[0],
                onChange: (language) => {
                    resetScrollBarOfScrollablePane();
                    this._onOptionsChanged!(
                        {
                            selectLanguage: language!.text,
                        },
                        matchDatasetVersion!
                    );
                },
            },
            {
                key: "categories",
                text: "Category:",
                options: categoryList,
                selectedKey: selectCategory ?? categoryList[0],
                onChange: (catagory) => {
                    resetScrollBarOfScrollablePane();
                    this._onOptionsChanged!(
                        {
                            selectCategory: catagory!.text,
                        },
                        matchDatasetVersion!
                    );
                },
            },
        ];
        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }

    private onDismiss(imageId: any) {
        this.setState({
            selectImageId: imageId,
        });
    }

    private getDisplayEvalData(displayItems: any[]) {
        this.setState({
            imageVisItems: displayItems,
        });
    }
}
